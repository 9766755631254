var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, TextField, Tooltip } from '@mui/material';
// hooks
import useEventListener from '../../hooks/useEventListener';
// translation
import { useLocales } from '../../locales';
export default function RHFCodes(_a) {
    var _b = _a.keyName, keyName = _b === void 0 ? '' : _b, _c = _a.inputs, inputs = _c === void 0 ? [] : _c, _d = _a.codeLength, codeLength = _d === void 0 ? 8 : _d, other = __rest(_a, ["keyName", "inputs", "codeLength"]);
    var codesRef = useRef(null);
    var translate = useLocales().translate;
    var _e = useFormContext(), control = _e.control, setValue = _e.setValue;
    var handlePaste = function (event) {
        var data = event.clipboardData.getData('text');
        data = data.split('');
        inputs.map(function (input, index) { return setValue(input, data[index]); });
        event.preventDefault();
    };
    var handleChangeWithNextField = function (event, handleChange) {
        var _a = event.target, maxLength = _a.maxLength, value = _a.value, name = _a.name;
        var fieldIndex = name.replace(keyName, '');
        var fieldIntIndex = Number(fieldIndex);
        var nextfield = document.querySelector("input[name=".concat(keyName).concat(fieldIntIndex + 1, "]"));
        if (value.length > maxLength) {
            event.target.value = value[0];
        }
        if (value.length >= maxLength && fieldIntIndex < codeLength && nextfield !== null) {
            nextfield.focus();
        }
        handleChange(event);
    };
    useEventListener('paste', handlePaste, codesRef);
    return (_jsx(Stack, __assign({ direction: "row", spacing: 2, justifyContent: "center", ref: codesRef }, { children: inputs.map(function (name, index) { return (_jsx(Controller, { name: "".concat(keyName).concat(index + 1), control: control, render: function (_a) {
                var field = _a.field, error = _a.fieldState.error;
                return (_jsx(Tooltip, __assign({ title: "".concat(translate(name)) }, { children: _jsx(TextField, __assign({}, field, { error: !!error, autoFocus: index === 0, placeholder: "-", onChange: function (event) {
                            handleChangeWithNextField(event, field.onChange);
                        }, onFocus: function (event) { return event.currentTarget.select(); }, InputProps: {
                            sx: {
                                width: { xs: 36, sm: 56 },
                                height: { xs: 36, sm: 56 },
                                '& input': { p: 0, textAlign: 'center' },
                            },
                        }, inputProps: {
                            maxLength: 1,
                            type: 'string',
                        } }, other)) })));
            } }, name)); }) })));
}
